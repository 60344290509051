// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\r\nbody,\r\n#root,\r\n.app{\r\n    height:100%;\r\n    width:100%;\r\n    font-family:\"Inter\",sans-serif;\r\n}\r\n::-webkit-scrollbar{\r\n    width: 10px;\r\n}\r\n::-webkit-scrollbar-track{\r\n    background: #7a7f9d;\r\n}\r\n::-webkit-scrollbar-thumb{\r\n    background: #21295c;\r\n}\r\n::-webkit-scrollbar-track:hover{\r\n    background: #21295c;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;IAII,WAAW;IACX,UAAU;IACV,8BAA8B;AAClC;AACA;IACI,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');\r\n\r\nhtml,\r\nbody,\r\n#root,\r\n.app{\r\n    height:100%;\r\n    width:100%;\r\n    font-family:\"Inter\",sans-serif;\r\n}\r\n::-webkit-scrollbar{\r\n    width: 10px;\r\n}\r\n::-webkit-scrollbar-track{\r\n    background: #7a7f9d;\r\n}\r\n::-webkit-scrollbar-thumb{\r\n    background: #21295c;\r\n}\r\n::-webkit-scrollbar-track:hover{\r\n    background: #21295c;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
