import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box, useTheme } from '@mui/material'
import Header from 'components/Header'
import DataGridCustomToolbar from 'components/DataGridCustomToolbar'

const Transactions = () => {
    const theme = useTheme()

    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(20)
    const [sortModel, setSortModel] = useState([])
    const [search, setSearch] = useState("")
    const [searchInput, setSearchInput] = useState("")
    const [transactions, setTransactions] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const fetchTransactions = async () => {
        setIsLoading(true)
        try {
            const response = await fetch('https://vehicles.rajnikantmahato.me/payments')
            if (!response.ok) {
                throw new Error('Failed to fetch data')
            }
            const data = await response.json()
            setTransactions(data)
            setTotal(data.length) // Assuming data has length property
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchTransactions()
    }, []) // Run once on component mount

    const columns = [
        {
            field: "_id",
            headerName: "ID",
            flex: 1
        },
        {
            field: "customerName",
            headerName: "Customer Name",
            flex: 1,
            valueGetter: (params) => params.row.customerDetails.customerName
        },
        {
            field: "customerEmail",
            headerName: "Customer Email",
            flex: 1,
            valueGetter: (params) => params.row.customerDetails.customerEmail
        },
        {
            field: "reason",
            headerName: "Reason",
            flex: 1
        },
        {
            field: "orderAmount",
            headerName: "Order Amount",
            flex: 1
        },
        {
            field: "companyName",
            headerName: "Company Name",
            flex: 1
        },
        {
            field: "address",
            headerName: "Address",
            flex: 1
        },
        {
            field: "orderId",
            headerName: "Order ID",
            flex: 1
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1
        },
        {
            field: "createdAt",
            headerName: "Created At",
            flex: 1
        },
    ]
    

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="TRANSACTIONS" subtitle="List of all transactions" />
            <Box
                mt="40px"
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`
                    }
                }}
            >
                <DataGrid
                    loading={isLoading}
                    rows={transactions}
                    columns={columns}
                    rowCount={total}
                    roPageOptions={[20, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    components={{ Toolbar: DataGridCustomToolbar }}
                    componentsProps={{
                        toolbar: { searchInput, setSearchInput, setSearch }
                    }}
                    getRowId={(row) => row._id} // Use _id as the unique identifier
                />
            </Box>
        </Box>
    )
}

export default Transactions
