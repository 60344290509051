import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box, useTheme, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import Header from 'components/Header'
import DataGridCustomToolbar from 'components/DataGridCustomToolbar'

const Vehicles = () => {
    const theme = useTheme()

    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(20)
    const [sortModel, setSortModel] = useState([])
    const [search, setSearch] = useState("")
    const [searchInput, setSearchInput] = useState("")
    const [vehicles, setVehicles] = useState([])
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedVehicle, setSelectedVehicle] = useState(null)
    const [openDialog, setOpenDialog] = useState(false)

    const fetchVehicles = async () => {
        setIsLoading(true)
        try {
            const response = await fetch('https://vehicles.rajnikantmahato.me/vehicles')
            if (!response.ok) {
                throw new Error('Failed to fetch data')
            }
            const data = await response.json()
            setVehicles(data)
            setTotal(data.length)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchVehicles()
    }, [])

    const handleViewRcData = (vehicle) => {
        setSelectedVehicle(vehicle)
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const columns = [
        {
            field: "_id",
            headerName: "ID",
            flex: 1
        },
        {
            field: "userid",
            headerName: "User ID",
            flex: 1
        },
        {
            field: "vehicleNo",
            headerName: "Vehicle Number",
            flex: 1
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1
        },
        {
            field: "date",
            headerName: "Date",
            flex: 1
        },
        {
            field: "viewRcData",
            headerName: "View RC Data",
            flex: 1,
            renderCell: (params) => (
                <Button onClick={() => handleViewRcData(params.row)} variant="outlined" color="secondary">View RC Data</Button>
            )
        }
    ]

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="VEHICLE DETAILS" subtitle="List of all vehicles" />
            <Box
                mt="40px"
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`
                    }
                }}
            >
                <DataGrid
                    loading={isLoading}
                    rows={vehicles}
                    columns={columns}
                    rowCount={total}
                    roPageOptions={[20, 50, 100]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    components={{ Toolbar: DataGridCustomToolbar }}
                    componentsProps={{
                        toolbar: { searchInput, setSearchInput, setSearch }
                    }}
                    getRowId={(row) => row._id}
                />
            </Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>RC Data</DialogTitle>
                <DialogContent>
                {selectedVehicle && (
                    <table>
                    <tbody>
                        {Object.entries(selectedVehicle.rcData).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>{Array.isArray(value) ? value.join(', ') : value}</td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                )}
                </DialogContent>



                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Vehicles
