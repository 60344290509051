import React, { useState, useEffect } from "react";
import { useTheme, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Header from 'components/Header';

const Customers = () => {
    const theme = useTheme();
    const [data, setData] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [newUser, setNewUser] = useState({
        username: '',
        email: '',
        password: '',
        companyname: '',
        number: '',
        credits: '',
        role: ''
    });
    const [editUser, setEditUser] = useState({
        _id: '',
        username: '',
        email: '',
        companyname: '',
        number: '',
        credits: '',
        role: ''
    });

    const fetchData = async () => {
        try {
            const response = await fetch('https://vehicles.rajnikantmahato.me/users');
            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
       

        fetchData();
    }, []);

    const handleClickOpenAdd = () => {
        setOpenAdd(true);
    };

    const handleCloseAdd = () => {
        setOpenAdd(false);
    };

    const handleAddUserChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddUser = async () => {
        try {
            const response = await fetch('https://vehicles.rajnikantmahato.me/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newUser)
            });
            const result = await response.json();
            if (result.success) {
                setData([...data, result.user]);
                alert('Customer added successfully.');
                handleCloseAdd();
            } else {
                alert('Error: ' + result);
            }
        } catch (error) {
            alert('There was an error adding the customer.');
        }
    };

    const handleClickOpenEdit = (user) => {
        setEditUser(user);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleEditUserChange = (e) => {
        const { name, value } = e.target;
        setEditUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEditUser = async () => {
        try {
            const response = await fetch(`https://vehicles.rajnikantmahato.me/users/${editUser._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editUser)
            });
            const result = await response.json();
            if (result.success) {
                setData(data.map(user => (user._id === editUser._id ? editUser : user)));
                alert('Customer updated successfully.\n'+result.msg);
                handleCloseEdit();
            } else {
                alert('Error: ' + result.msg);
            }
        } catch (error) {
            alert('There was an error updating the customer.');
        }
    };

    const handleDeleteUser = async (id) => {
        try {
            const response = await fetch(`https://vehicles.rajnikantmahato.me/users/${id}`, {
                method: 'DELETE',
            });
            const result = await response.json();
            if (result.success) {
                setData(data.filter(user => user._id !== id));
                alert('Customer deleted successfully.\n'+result.msg);
            } else {
                alert('Error: ' + result.msg);
            }
        } catch (error) {
            alert('There was an error deleting the customer.');
        }
    };

    const columns = [
        {
            field: "_id",
            headerName: "ID",
            flex: 1
        },
        {
            field: "name",
            headerName: "Name",
            flex: 0.5
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1
        },
        {
            field: "number",
            headerName: "Phone",
            flex: 0.5,
        },
        {
            field: "credits",
            headerName: "Credits",
            flex: 0.5,
        },
        
        {
            field: "role",
            headerName: "Role",
            flex: 0.5
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleClickOpenEdit(params.row)} color="secondary">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteUser(params.row._id)} color="secondary">
                        <DeleteIcon />
                    </IconButton>
                </>
            )
        }
    ];

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="CUSTOMERS" subtitle="List of customers" />
            <Box display="flex" justifyContent="flex-end" mb="20px">
                <Button variant="contained" color="primary" onClick={handleClickOpenAdd}>
                    Add New Customer
                </Button>
            </Box>
            <Box
                mt="40px"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none"
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none"
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`
                    }
                }}
            >
                <DataGrid
                    loading={!data.length}
                    getRowId={(row) => row._id}
                    rows={data || []}
                    columns={columns}
                />
            </Box>
            <Dialog open={openAdd} onClose={handleCloseAdd}>
                <DialogTitle>Add New Customer</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="username"
                        label="Name"
                        type="text"
                        fullWidth
                        value={newUser.username}
                        onChange={handleAddUserChange}
                    />
                    <TextField
                        margin="dense"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={newUser.email}
                        onChange={handleAddUserChange}
                    />
                    <TextField
                        margin="dense"
                        name="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={newUser.password}
                        onChange={handleAddUserChange}
                    />
                    <TextField
                        margin="dense"
                        name="companyname"
                        label="Company Name"
                        type="text"
                        fullWidth
                        value={newUser.companyname}
                        onChange={handleAddUserChange}
                    />
                    <TextField
                        margin="dense"
                        name="number"
                        label="Phone"
                        type="text"
                        fullWidth
                        value={newUser.number}
                        onChange={handleAddUserChange}
                    />

                     <TextField
                        margin="dense"
                        name="credits"
                        label="Credits"
                        type="text"
                        fullWidth
                        value={newUser.credits}
                        onChange={handleAddUserChange}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId="role-label"
                            name="role"
                            value={newUser.role}
                            onChange={handleAddUserChange}
                        >
                            <MenuItem value="user">User</MenuItem>
                            <MenuItem value="rc-challanadv">Vehicle Validation</MenuItem>
                            <MenuItem value="rc-basic">RC Basic</MenuItem>
                            <MenuItem value="rc-advapi">RC Plus</MenuItem>
                            <MenuItem value="rc-challanapi">E Challan</MenuItem>
                            <MenuItem value="Challan">Challan</MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="superadmin">Superadmin</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAdd} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddUser} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openEdit} onClose={handleCloseEdit}>
                <DialogTitle>Edit Customer</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="username"
                        label="Name"
                        type="text"
                        fullWidth
                        value={editUser.username}
                        onChange={handleEditUserChange}
                    />
                    <TextField
                        margin="dense"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={editUser.email}
                        onChange={handleEditUserChange}
                    />
                    {/* <TextField
                        margin="dense"
                        name="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={editUser.password}
                        onChange={handleEditUserChange}
                    /> */}

                    <TextField
                        margin="dense"
                        name="companyname"
                        label="Company Name"
                        type="text"
                        fullWidth
                        value={editUser.companyname}
                        onChange={handleEditUserChange}
                    />
                    <TextField
                        margin="dense"
                        name="number"
                        label="Phone"
                        type="text"
                        fullWidth
                        value={editUser.number}
                        onChange={handleEditUserChange}
                    />

                    <TextField
                        margin="dense"
                        name="credits"
                        label="Credits"
                        type="text"
                        fullWidth
                        value={editUser.credits}
                        onChange={handleEditUserChange}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId="role-label"
                            name="role"
                            value={editUser.role}
                            onChange={handleEditUserChange}
                        >
                           <MenuItem value="user">User</MenuItem>
                            <MenuItem value="rc-challanadv">Vehicle Validation</MenuItem>
                            <MenuItem value="rc-basic">RC Basic</MenuItem>
                            <MenuItem value="rc-advapi">RC Plus</MenuItem>
                            <MenuItem value="rc-challanapi">E Challan</MenuItem>
                            <MenuItem value="Challan">Challan</MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="superadmin">Superadmin</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEdit} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditUser} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Customers;
